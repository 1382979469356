import { createReducer, ActionType } from 'typesafe-actions';

import * as types from './departments.actionTypes';
import * as actions from './departments.action';
import { DepartmentsState } from './type';

export type DepartmentsAction = ActionType<typeof actions>;

export const initialState: DepartmentsState = {
	filterQuery: '',
	sortBy: 'name',
	key: 'asc',
	name: 'asc',
	description: 'asc',
	email: 'asc',
	selectedDepartmentKey: null,
	selectedDepartment: null,
	selectedDepartmentDeleteKey: null,
	selectedTab: 'published',

	departments: null,
	departmentsPaginate: null,
	departmentsIndex: null,
	departmentIndex: null,
	pageIndex: 1,
	isLoaded: false,
	isLoadedDepartments: false,
	isLoadingDepartments: false,
	isLoadingDepartmentIndex: false,
	lastUpdatedDepartments: null,
	lastUpdatedDepartmentIndex: null,
	isLoadingDepartmentIndexWithRoles: false,
	departmentIndexWithRoles: null,

	error: null,

	departmentsUserFilterOptions: null,
	showDepartmentBulkModal: false,
	showDepartmentDeleteModal: false,
	totalDepartments: null,
	isLoadingPaginate: false,
	isLoadingSchedule: false,
	departmentSchedule: null,
	departmentsDataVisibility: null,
	departmentsWithDataVisibility: null,
};

const departmentsReducer = createReducer<DepartmentsState, DepartmentsAction>(initialState)
	.handleAction(actions.fetchDepartments.request, (state) => ({
		...state,
		error: null,
		isLoadingDepartments: true,
		isLoadedDepartments: false,
	}))
	.handleAction(actions.fetchDepartments.success, (state, action) => ({
		...state,
		departments: action.payload.data,
		isLoadingDepartments: false,
		lastUpdatedDepartments: Date.now(),
		isLoadedDepartments: true,
	}))
	.handleAction(actions.fetchDepartments.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoadingDepartments: true,
	}))
	.handleAction(actions.fetchDepartmentsUserFilterOptions.request, (state) => ({
		...state,
		error: null,
		isLoadingDepartments: true,
		isLoadedDepartments: false,
	}))
	.handleAction(actions.fetchDepartmentsUserFilterOptions.success, (state, action) => ({
		...state,
		departmentsUserFilterOptions: action.payload.data,
		isLoadingDepartments: false,
		lastUpdatedDepartments: Date.now(),
		isLoadedDepartments: true,
	}))
	.handleAction(actions.fetchDepartmentsUserFilterOptions.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoadingDepartments: true,
	}))
	.handleAction(actions.fetchPaginateDepartments.request, (state) => ({
		...state,
		error: null,
		isLoadingDepartments: true,
		isLoadedDepartments: false,
	}))
	.handleAction(actions.fetchPaginateDepartments.success, (state, action) => ({
		...state,
		departmentsPaginate: action.payload.data,
		isLoadingDepartments: false,
		lastUpdatedDepartments: Date.now(),
		totalDepartments: action.payload.total,
		isLoadedDepartments: true,
	}))
	.handleAction(actions.fetchPaginateDepartments.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoadingDepartments: true,
	}))
	.handleAction(actions.fetchDepartmentSchedule.request, (state) => ({
		...state,
		error: null,
		isLoadingSchedule: true,
	}))
	.handleAction(actions.fetchDepartmentSchedule.success, (state, action) => ({
		...state,
		isLoadingSchedule: false,
		departmentSchedule: action.payload.data.length,
	}))
	.handleAction(actions.fetchDepartmentSchedule.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoadingSchedule: false,
	}))
	.handleAction(actions.fetchDepartmentIndexById.request, (state) => ({
		...state,
		error: null,
		isLoadingDepartmentIndex: true,
		isLoaded: false,
	}))
	.handleAction(actions.fetchDepartmentIndexById.success, (state, action) => ({
		...state,
		departmentIndex: action.payload.data,
		lastUpdatedDepartmentIndex: Date.now(),
		isLoadingDepartmentIndex: false,
		isLoaded: true,
	}))
	.handleAction(actions.fetchDepartmentIndexById.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoadingDepartmentIndex: false,
		isLoaded: true,
	}))
	.handleAction(actions.fetchDepartmentIndexWithUserRolesById.request, (state) => ({
		...state,
		error: null,
	}))
	.handleAction(actions.fetchDepartmentIndexWithUserRolesById.success, (state, action) => ({
		...state,
		departmentIndexWithRoles: action.payload.data,
		isLoaded: true,
	}))
	.handleAction(actions.fetchDepartmentIndexWithUserRolesById.failure, (state, action) => ({
		...state,
		error: action.payload.error,
	}))
	.handleAction(actions.fetchDepartmentsIndex.request, (state) => ({
		...state,
		error: null,
		isLoadingDepartmentIndexWithRoles: true,
	}))
	.handleAction(actions.fetchDepartmentsIndex.success, (state, action) => ({
		...state,
		departmentsIndex: action.payload.data,
		isLoadingDepartmentIndex: false,
	}))
	.handleAction(actions.fetchDepartmentsIndex.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		lastUpdatedDepartmentIndex: Date.now(),
		isLoadingDepartmentIndex: false,
	}))
	.handleAction(actions.fetchDepartmentsDataVisibility.request, (state) => ({
		...state,
		error: null,
		isLoadingDepartments: true,
		isLoadedDepartments: false,
	}))
	.handleAction(actions.fetchDepartmentsDataVisibility.success, (state, action) => ({
		...state,
		departmentsDataVisibility: action.payload.data,
		isLoadingDepartments: false,
		lastUpdatedDepartments: Date.now(),
		isLoadedDepartments: true,
	}))
	.handleAction(actions.fetchDepartmentsDataVisibility.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoadingDepartments: true,
	}))
	.handleAction(actions.fetchDepartmentsWithDataVisibility.request, (state) => ({
		...state,
		error: null,
		isLoadingDepartments: true,
		isLoadedDepartments: false,
	}))
	.handleAction(actions.fetchDepartmentsWithDataVisibility.success, (state, action) => ({
		...state,
		departmentsWithDataVisibility: action.payload.data,
		isLoadingDepartments: false,
		lastUpdatedDepartments: Date.now(),
		isLoadedDepartments: true,
	}))
	.handleAction(actions.fetchDepartmentsWithDataVisibility.failure, (state, action) => ({
		...state,
		error: action.payload.error,
		isLoadingDepartments: true,
	}))
	.handleAction(types.SET_FILTER_QUERY as any, (state, action) => ({
		...state,
		filterQuery: action.payload.text,
	}))
	.handleAction(types.CLEAR_FILTER_QUERY as any, (state) => ({
		...state,
		filterQuery: '',
	}))
	.handleAction(types.CLEAR_DEPARTMENT_INDEX_WITH_ROLES_BY_ID as any, (state) => ({
		...state,
		departmentIndexWithRoles: null,
	}))
	.handleAction(types.SET_SHOW_DEPARTMENT_BULK_MODAL as any, (state, action) => ({
		...state,
		showDepartmentBulkModal: action.payload.value,
		error: '',
	}))
	.handleAction(types.SET_SELECTED_TAB as any, (state, action) => ({
		...state,
		selectedTab: action.payload.value,
	}))
	.handleAction(types.CLEAR_DEPARTMENT_INDEX as any, (state, action) => {
		return {
			...state,
			departmentIndex: null,
		};
	})
	.handleAction(types.SET_SHOW_DEPARTMENT_DELETE_MODAL as any, (state, action) => {
		return {
			...state,
			showDepartmentDeleteModal: true,
			selectedDepartmentDeleteKey: action.payload.value,
		};
	})
	.handleAction(types.SET_DISMISS_DEPARTMENT_DELETE_MODAL as any, (state, action) => {
		return {
			...state,
			showDepartmentDeleteModal: false,
			selectedDepartmentDeleteKey: null,
		};
	})
	.handleAction(types.SET_SORT as any, (state, action) => {
		if (state.sortBy !== action.payload.newType) {
			return {
				...state,
				sortBy: action.payload.newType,
				key: 'asc',
				name: 'asc',
				description: 'asc',
				email: 'asc',
			};
		}
		return {
			...state,
			[action.payload.newType]: action.payload.newOrder,
		};
	})
	.handleAction(types.SET_PAGE_INDEX as any, (state, action) => {
		return {
			...state,
			pageIndex: action.payload.value,
		};
	})
	.handleAction(types.SET_LOADING as any, (state, action) => {
		return { ...state, isLoading: action.payload.value };
	})
	.handleAction(types.SET_PAGE_INDEX as any, (state, action) => {
		return { ...state, pageIndex: action.payload.value };
	});
export { departmentsReducer };
