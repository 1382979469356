import { createAsyncAction, action } from 'typesafe-actions';
import { DepartmentIndex, DepartmentIndexUserInSite } from '@nimbly-technologies/nimbly-common';

import * as types from './departments.actionTypes';
import { Departments, UpdateDepartementByRolesSagaPayload } from './type';

export const clearDepartmentsByRoles = () => action(types.CLEAR_DEPARTMENT_INDEX_WITH_ROLES_BY_ID);

export const clearDepartmentsFilterQuery = (text: string) => action(types.CLEAR_FILTER_QUERY, { text });

export const setDepartmentsFilterQuery = (text: string) => action(types.SET_FILTER_QUERY, { text });

export const clearDepartmentIndex = () => action(types.CLEAR_DEPARTMENT_INDEX);

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });

export const setShowDepartmentBulkModal = (value: boolean) => action(types.SET_SHOW_DEPARTMENT_BULK_MODAL, { value });

export const setShowDepartmentDeleteModal = (value: string) =>
	action(types.SET_SHOW_DEPARTMENT_DELETE_MODAL, { value });

export const setDimissDepartmentDeleteModal = () => action(types.SET_DISMISS_DEPARTMENT_DELETE_MODAL);

export const setSortDepartment = (newType: string, newOrder: 'asc' | 'desc' | null = null) =>
	action(types.SET_SORT, { newType, newOrder });

export const setPageIndex = (value: number) => action(types.SET_PAGE_INDEX, { value });

export const setTotalDepartments = (value: number) => action(types.SET_TOTAL_DEPARTMENTS, { value });

export const setSelectedTab = (value: string) => action(types.SET_SELECTED_TAB, { value });

export const fetchDepartmentIndexWithUserRolesById = createAsyncAction(
	types.FETCH_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_REQUEST,
	types.FETCH_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_SUCCESS,
	types.FETCH_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_FAILURE,
)<{ deptID: string }, { data: DepartmentIndexUserInSite | null }, { error: string }>();

export const fetchDepartments = createAsyncAction(
	types.FETCH_DEPARTMENTS_REQUEST,
	types.FETCH_DEPARTMENTS_SUCCESS,
	types.FETCH_DEPARTMENTS_FAILURE,
)<undefined, { data: Departments | null }, { error: string }>();

export const fetchDepartmentsUserFilterOptions = createAsyncAction(
	types.FETCH_DEPARTMENTS_USER_FILTER_REQUEST,
	types.FETCH_DEPARTMENTS_USER_FILTER_SUCCESS,
	types.FETCH_DEPARTMENTS_USER_FILTER_FAILURE,
)<undefined, { data: Departments | null }, { error: string }>();

export const updateDepartmentIndexWithUserRolesById = createAsyncAction(
	types.UPDATE_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_REQUEST,
	types.UPDATE_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_SUCCESS,
	types.UPDATE_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_FAILURE,
)<{ data: UpdateDepartementByRolesSagaPayload }, { data: {}; error: string; message: string }, { error: string }>();

export const fetchDepartmentsIndex = createAsyncAction(
	types.FETCH_DEPARTMENTS_INDEX_REQUEST,
	types.FETCH_DEPARTMENTS_INDEX_SUCCESS,
	types.FETCH_DEPARTMENTS_INDEX_FAILURE,
)<undefined, { data: { [key: string]: DepartmentIndex } | null }, { error: string }>();

export const fetchDepartmentIndexById = createAsyncAction(
	types.FETCH_DEPARTMENT_INDEX_BY_ID_REQUEST,
	types.FETCH_DEPARTMENT_INDEX_BY_ID_SUCCESS,
	types.FETCH_DEPARTMENT_INDEX_BY_ID_FAILURE,
)<{ deptID: string }, { data: DepartmentIndex | null }, { error: string }>();

interface FetchPaginateDepartmentsPayload {
	limit: number;
}

export const fetchPaginateDepartments = createAsyncAction(
	types.FETCH_PAGINATE_DEPARTMENTS_REQUEST,
	types.FETCH_PAGINATE_DEPARTMENTS_SUCCESS,
	types.FETCH_PAGINATE_DEPARTMENTS_FAILURE,
)<FetchPaginateDepartmentsPayload, { data: Departments | null; total: number | null }, { error: string }>();

export const fetchDepartmentSchedule = createAsyncAction(
	types.FETCH_DEPARTMENT_SCHEDULE_REQUEST,
	types.FETCH_DEPARTMENT_SCHEDULE_SUCCESS,
	types.FETCH_DEPARTMENT_SCHEDULE_FAILURE,
)<undefined, any, { error: string }>();

export const fetchDepartmentsDataVisibility = createAsyncAction(
	types.FETCH_DEPARTMENTS_DATA_VISIBILITY_REQUEST,
	types.FETCH_DEPARTMENTS_DATA_VISIBILITY_SUCCESS,
	types.FETCH_DEPARTMENTS_DATA_VISIBILITY_FAILURE,
)<undefined, { data: Departments | null }, { error: string }>();

export const fetchDepartmentsWithDataVisibility = createAsyncAction(
	types.FETCH_DEPARTMENTS_WITH_DATA_VISIBILITY_REQUEST,
	types.FETCH_DEPARTMENTS_WITH_DATA_VISIBILITY_SUCCESS,
	types.FETCH_DEPARTMENTS_WITH_DATA_VISIBILITY_FAILURE,
)<undefined, { data: Departments | null }, { error: string }>();
