export const SET_FILTER_QUERY = 'departments/SET_FILTER_QUERY';
export const CLEAR_FILTER_QUERY = 'departments/CLEAR_FILTER_QUERY';
export const CLEAR_DEPARTMENT_INDEX = 'departments/CLEAR_DEPARTMENT_INDEX';
export const SET_SORT = 'departments/SET_AUDITOR_SORT';
export const SET_LOADING = 'departments/SET_LOADING';
export const SET_SHOW_BULK_USER_MODAL = 'departments/SET_SHOW_BULK_USER_MODAL';
export const SET_SHOW_DEPARTMENT_BULK_MODAL = 'departments/SET_SHOW_DEPARTMENT_BULK_MODAL';
export const SET_SHOW_DEPARTMENT_DELETE_MODAL = 'departments/SET_SHOW_DEPARTMENT_DELETE_MODAL';
export const SET_DISMISS_DEPARTMENT_DELETE_MODAL = 'departments/SET_DISMISS_DEPARTMENT_DELETE_MODAL';
export const SET_PAGE_INDEX = 'departments/SET_PAGE_INDEX ';
export const SET_TOTAL_DEPARTMENTS = 'departments/SET_TOTAL_DEPARTMENTS';
export const SET_SELECTED_TAB = 'departments/SET_SELECTED_TAB';

export const FETCH_DEPARTMENTS_REQUEST = 'departments/FETCH_DEPARTMENTS_REQUEST';
export const FETCH_DEPARTMENTS_SUCCESS = 'departments/FETCH_DEPARTMENTS_SUCCESS';
export const FETCH_DEPARTMENTS_FAILURE = 'departments/FETCH_DEPARTMENTS_FAILURE';

export const FETCH_DEPARTMENTS_USER_FILTER_REQUEST = 'departments/FETCH_DEPARTMENTS_USER_FILTER_REQUEST';
export const FETCH_DEPARTMENTS_USER_FILTER_SUCCESS = 'departments/FETCH_DEPARTMENTS_USER_FILTER_SUCCESS';
export const FETCH_DEPARTMENTS_USER_FILTER_FAILURE = 'departments/FETCH_DEPARTMENTS_USER_FILTER_FAILURE';

export const FETCH_PAGINATE_DEPARTMENTS_REQUEST = 'departments/FETCH_PAGINATE_DEPARTMENTS_REQUEST';
export const FETCH_PAGINATE_DEPARTMENTS_SUCCESS = 'departments/FETCH_PAGINATE)DEPARTMENTS_SUCCESS';
export const FETCH_PAGINATE_DEPARTMENTS_FAILURE = 'departments/FETCH_PAGINATE_DEPARTMENTS_FAILURE';

export const FETCH_DEPARTMENTS_INDEX_REQUEST = 'departments/FETCH_DEPARTMENTS_INDEX_REQUEST';
export const FETCH_DEPARTMENTS_INDEX_SUCCESS = 'departments/FETCH_DEPARTMENTS_INDEX_SUCCESS';
export const FETCH_DEPARTMENTS_INDEX_FAILURE = 'departments/FETCH_DEPARTMENTS_INDEX_FAILURE';

export const FETCH_DEPARTMENT_INDEX_BY_ID_REQUEST = 'departments/FETCH_DEPARTMENT_INDEX_BY_ID_REQUEST';
export const FETCH_DEPARTMENT_INDEX_BY_ID_SUCCESS = 'departments/FETCH_DEPARTMENT_INDEX_BY_ID_SUCCESS';
export const FETCH_DEPARTMENT_INDEX_BY_ID_FAILURE = 'departments/FETCH_DEPARTMENT_INDEX_BY_ID_FAILURE';

export const FETCH_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_REQUEST =
	'departments/FETCH_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_REQUEST';
export const FETCH_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_SUCCESS =
	'departments/FETCH_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_SUCCESS';
export const FETCH_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_FAILURE =
	'departments/FETCH_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_FAILURE';

export const UPDATE_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_REQUEST =
	'departments/UPDATE_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_REQUEST';
export const UPDATE_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_SUCCESS =
	'departments/UPDATE_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_SUCCESS';
export const UPDATE_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_FAILURE =
	'departments/UPDATE_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_FAILURE';
export const CLEAR_DEPARTMENT_INDEX_WITH_ROLES_BY_ID = 'departments/CLEAR_DEPARTMENT_INDEX_WITH_ROLES_BY_ID';

export const FETCH_DEPARTMENT_SCHEDULE_REQUEST = 'departments/FETCH_DEPARTMENT_SCHEDULE_REQUEST ';
export const FETCH_DEPARTMENT_SCHEDULE_SUCCESS = 'departments/FETCH_DEPARTMENT_SCHEDULE_SUCCESS ';
export const FETCH_DEPARTMENT_SCHEDULE_FAILURE = 'departments/FETCH_DEPARTMENT_SCHEDULE_FAILURE';

export const FETCH_DEPARTMENTS_DATA_VISIBILITY_REQUEST = 'departments/FETCH_DEPARTMENTS_DATA_VISIBILITY_REQUEST';
export const FETCH_DEPARTMENTS_DATA_VISIBILITY_SUCCESS = 'departments/FETCH_DEPARTMENTS_DATA_VISIBILITY_SUCCESS';
export const FETCH_DEPARTMENTS_DATA_VISIBILITY_FAILURE = 'departments/FETCH_DEPARTMENTS_DATA_VISIBILITY_FAILURE';

export const FETCH_DEPARTMENTS_WITH_DATA_VISIBILITY_REQUEST =
	'departments/FETCH_DEPARTMENTS_WITH_DATA_VISIBILITY_REQUEST';
export const FETCH_DEPARTMENTS_WITH_DATA_VISIBILITY_SUCCESS =
	'departments/FETCH_DEPARTMENTS_WITH_DATA_VISIBILITY_SUCCESS';
export const FETCH_DEPARTMENTS_WITH_DATA_VISIBILITY_FAILURE =
	'departments/FETCH_DEPARTMENTS_WITH_DATA_VISIBILITY_FAILURE';
